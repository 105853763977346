<script lang="jsx">
import ClientOnly from 'vue-client-only'
import { transformImg } from '@shein/common-function'
import ColorV2AddTag from './ColorV2AddTag.vue'
import CropImageContainerJsx from 'public/src/pages/components/CropImageContainer/CropImageContainerJsx.vue'
import CropImageContainerPreposeJsx from './CropImageContainerPreposeJsx.vue'

export default {
  name: 'ColorV2Radio',
  functional: true,
  components: {
    ClientOnly,
    ColorV2AddTag,
  },
  props: {
    colorType: {
      type: String,
      default: 'default',
    },
    loadConf: {
      type: Object,
      default: () => ({
        size: '750x',
        num: 1
      }),
    },
    colorsInfo: {
      type: Array,
      default: () => [],
    },
    detail: {
      type: Object,
      default: () => ({}),
    },
    eventCategory: {
      type: String,
      default: '',
    },
    reviewLocation: {
      type: String,
      default: '',
    },
    from: {
      type: String,
      default: '',
    },
    colorConf: {
      type: Object,
      default: () => ({}),
    },
    promotionTagInfos: {
      type: Object,
      default: () => ({}),
    },
    switchColor: {
      type: Function,
      default: () => {},
    },
    labelExpose: {
      type: Function,
      default: () => {}
    },
    lazyImg: {
      type: String,
      default: ''
    },
    fixedRatio: {
      type: String,
      default: ''
    },
    isSupportCropImage: {
      type: Boolean,
      default: false
    },
    /**
     * 加载状态
     */
    loading: {
      type: Boolean,
    },
    /**
     * 最后点击的色块
     */
    lastClickColor: {
      type: String,
      default: ''
    },
    supportLargeImageList: {
      type: Boolean,
      default: false
    },
    analysisInfo: {
      type: Object,
      default: () => ({})
    },
    isSkcPrepose: {
      type: Boolean,
      default: false
    },
  },
  inject: {
    cutImg: {
      from: 'cutImg',
      default: (url) => url,
    },
  },
  render(h, { props, injections }) {
    const { cutImg } = injections || {}

    const { 
      colorType,
      loadConf,
      colorsInfo,
      detail,
      eventCategory,
      reviewLocation,
      from,
      colorConf,
      promotionTagInfos,
      switchColor,
      labelExpose,
      lazyImg,
      fixedRatio,
      isSupportCropImage,
      loading,
      lastClickColor,
      supportLargeImageList,
      analysisInfo,
      isSkcPrepose
    } = props

    const PUBLIC_CDN = gbCommonInfo?.PUBLIC_CDN || ''

    // 如果存在加载状态，显示最后点击的颜色
    const _useLastClickColor = loading && lastClickColor
    const currentGoodsId = _useLastClickColor ? +lastClickColor : +detail?.goods_id || ''

    const baseCutImg = (imgUrl, imgDesignWidth = 0, imgDataExp = {}) => {
      if (!cutImg) {
        return transformImg({ img: imgUrl })
      }
      return cutImg(imgUrl, imgDesignWidth, imgDataExp)
    }

    const renderColorItem = () => {
      return colorsInfo.map((item, key) => {
        const { goods_id, goods_title, isSoldoutColor, goods_sn } = item
        return (
          <li 
            key={goods_id} 
            {...{ directives: [{ name: 'ada', value: { level: 1, pos: key } }] }}
            role="text"
            aaa="v2"
            aria-label={goods_title}
            class={[{
              'goods-color__item': colorType == 'default',
              'goods-color__img-item': colorType == 'img',
              'goods-color__text-item': colorType == 'text',
              'goods-color__prepose-item': colorType == 'prepose',
              'size-sold-out': colorType == 'text' && isSoldoutColor && detail.goods_id == item.goods_id,
              'sold-out': colorType == 'text' && isSoldoutColor,
              'color-active': goods_id == currentGoodsId,
            }]}
            data-sku={goods_sn}
            data-goodsid={goods_id}
            data-soldout={Number(isSoldoutColor)}
            data-event-category={eventCategory}
            data-review-location={reviewLocation}
            data-is_front={isSkcPrepose ? 1 : 0}
            data-location={from}
            data-source={colorConf && colorConf.analysisConfig && colorConf.analysisConfig.source}
            data-promotion-type={promotionTagInfos && promotionTagInfos[goods_id] && promotionTagInfos[goods_id].typeId || '-'}
            data-image-tp="small"
            data-is-attr-fold={analysisInfo.isAttrFold}
            da-event-click="1-6-1-32"
            vOn:click={() => {
              switchColor(item)
            }}
          >
            {colorType !== 'prepose' && renderColorItemContent(item, key)}
            {colorType === 'prepose' && renderImgColorsPrepose(item, key)}
            <ClientOnly>
              <ColorV2AddTag
                item={item}
                isLargeImage={false}
                promotionTagInfos={promotionTagInfos}
                labelExpose={labelExpose}
                publicCdn={PUBLIC_CDN}
              />
            </ClientOnly>
          </li>
        )
      })
    }

    const renderColorItemContent = (item, key) => {
      let content = null
      if (colorType === 'default') {
        content = renderDefaultColors(item, key)
      } else if (colorType === 'img') {
        content = renderImgColors(item, key)
      } else {
        content = renderTxtColors(item, key)
      }

      return (
        <a
          href="javascript:;"
          aria-hidden="true"
        >
          {content}
          {renderColorSoldOut(item)}
        </a>
      )
    }

    /** 颜色色块(圆形)[默认] */
    const renderDefaultColors = (item, key) => {
      const { goods_id, goods_color_image, isSoldoutColor } = item
      return (
        <div 
          class={{
            'goods-color__item': true,
            'active': goods_id == currentGoodsId
          }}
          key={goods_id}
        >
          <div 
            style={{
              backgroundImage: `url(${baseCutImg(goods_color_image, 28, { max: 48 })})`
            }} 
            class={{
              'fsp-element': key === 0,
              'goods-color__item-inner': true,
              'soldout': isSoldoutColor
            }}
          >
          </div>
        </div>
        // <RadioColor
        //   value={currentGoodsId}
        //   label={goods_id}
        //   color-image={baseCutImg(goods_color_image, 28, { max: 48 })}
        //   size="radio52"
        //   margin-r="0"
        //   saleout={isSoldoutColor}
        // />
      )
    }

    /** 图片色块(方形) */
    const renderImgColorsPrepose = (item, key) => {
      const { isSoldoutColor, goods_title } = item
      // 前 7 个方形色块使用src直出
      if (key <= 6) {
        return (
          <CropImageContainerPreposeJsx
            fixedRatio={fixedRatio}
            isSupportCropImage={isSupportCropImage}
            imgSrc={baseCutImg(item['goods_thumb'], 80, { max: 100 })}
            colorBlockText={goods_title}
            saleOut={isSoldoutColor}
          />
        )
      }

      // 后续的使用懒加载
      return (
        <CropImageContainerPreposeJsx
          fixedRatio={fixedRatio}
          isSupportCropImage={isSupportCropImage}
          imgSrc={transformImg({ img: item['goods_thumb'] })}
          colorBlockText={goods_title}
          saleOut={isSoldoutColor}
          imgProps={{
            src: lazyImg,
            dataDesignWidth: '80',
            class: 'lazyload',
            dataExp: 'max=100'
          }}
        />
      )
    }

    /** 图片色块(方形) */
    const renderImgColors = (item, key) => {
      // 前 7 个方形色块使用src直出
      if (key <= 6) {
        return (
          <CropImageContainerJsx
            fixedRatio={fixedRatio}
            isSupportCropImage={isSupportCropImage}
            imgSrc={baseCutImg(item['goods_thumb'], 80, { max: 100 })}
          />
        )
      }

      // 后续的使用懒加载
      return (
        <CropImageContainerJsx
          fixedRatio={fixedRatio}
          isSupportCropImage={isSupportCropImage}
          imgSrc={transformImg({ img: item['goods_thumb'] })}
          imgProps={{
            src: lazyImg,
            dataDesignWidth: '80',
            class: 'lazyload',
            dataExp: 'max=100'
          }}
        />
      )
    }

    /** 文字色块 */
    const renderTxtColors = (item) => {
      const { goods_title } = item
      if (!goods_title) {
        return null
      }
      return goods_title
    }

    const renderColorSoldOut = (item) => {
      if (item.isSoldoutColor && colorType != 'default' && colorType != 'text') {
        return (
          <div
            class="goods-color__soldout"
          >
            <i></i>
          </div>
        )
      }
      return null
    }

    return (
      <ul
        class={[{
          'goods-color__imgs': colorType == 'img',
          'goods-color__list': colorType == 'default',
          'goods-color__text': colorType == 'text',
          'goods-color__prepose': colorType == 'prepose',
          'no-right-arrow': !supportLargeImageList,
          'goods-color__wrap': true,
          'goods-color__line': loadConf.isLine
        }]}
      >
        {renderColorItem()}
      </ul>
    )
  }
}

</script>
