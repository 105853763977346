<script lang="jsx">

import ClientOnly from 'vue-client-only'
import ColorV2AddTag from './ColorV2AddTag.vue'
import CropImageContainerJsx from 'public/src/pages/components/CropImageContainer/CropImageContainerJsx.vue'

export default {
  name: 'ColorV2LargeImage',
  functional: true,
  components: {
    ClientOnly,
    ColorV2AddTag
  },
  props: {
    list: {
      type: Array,
      default: () => ([]),
    },
    showList: {
      type: Boolean,
      default: false
    },
    eventCategory: {
      type: String,
      default: ''
    },
    reviewLocation: {
      type: String,
      default: ''
    },
    from: {
      type: String,
      default: '',
    },
    colorConf: {
      type: Object,
      default: () => ({})
    },
    promotionTagInfos: {
      type: Object,
      default: () => ({})
    },
    selectedColor: {
      type: String,
      default: '',
    },
    switchColor: {
      type: Function,
      default: () => {}
    },
    lazyImg: {
      type: String,
      default: ''
    },
    isRw: {
      type: Boolean,
      default: false,
    },
    publicCdn: {
      type: String,
      default: '',
    },
    fixedRatio: {
      type: String,
      default: ''
    },
    analysisInfo: {
      type: Object,
      default: () => ({})
    },
    isSupportCropImage: {
      type: Boolean,
      default: false
    }
  },
  render(h, { props }) {
    const {
      list,
      showList,
      eventCategory,
      reviewLocation, 
      from,
      colorConf,
      promotionTagInfos,
      selectedColor,
      switchColor,
      lazyImg,
      publicCdn,
      isRw,
      fixedRatio,
      isSupportCropImage,
      analysisInfo,
    } = props
    if (!showList) {
      return null
    }

    const fillLayout = !isRw && list?.length < 7

    const getRenderList = () => {
      if (!Array.isArray(list) || !list.length) {
        return []
      }

      const listLength = list.length
      if (fillLayout && (listLength > 3 && listLength < 7 )) {
        return [list.slice(0, 3), list.slice(3, listLength)]
      }
      if (listLength > (fillLayout ? 6 : 4)) {
        const firstLine = []
        const secondLine = []
        for (let i = 0; i < listLength; i++) {
          const item = list[i]
          if (i % 2 === 0) {
            firstLine.push(item)
          } else {
            secondLine.push(item)
          }
        }
        return [firstLine, secondLine]
      }
      return [list]
    }

    const renderList = getRenderList()

    const renderLineWidth = () => {
      if (!renderList.length) {
        return '100%'
      }
      const firstLineLength = renderList[0]?.length
      if (!firstLineLength || firstLineLength <= 2) {
        return '100%'
      }
      if (fillLayout) {
        return '100%'
      } else {
        return `${firstLineLength * 3.2 + (firstLineLength - 1) * 0.213333}rem`
      }
    }

    const lineWidth = renderLineWidth()

    const labelExpose = (goods_id) => {
      const promotionInfo = promotionTagInfos?.[goods_id]
      return {
        id: '1-6-1-124',
        once: true,
        data: {
          promotion_type: promotionInfo?.typeId,
          style: colorConf.analysisConfig?.source,
          skc: promotionInfo?.goods_sn
        }
      }
    }

    const renderListBox = () => {
      return renderList.map((item, index) => {
        return (
          <ul class="image-list" key={index} style={{ width: lineWidth }}>
            {renderItem(item)}
          </ul>
        )
      })
    }

    const renderItem = (line) => {
      return line.map((item, index) => {
        return (
          <li
            key={item.goods_id}
            aria-label={item.goods_title}
            class={[
              'image-item',
              { 'active': item.goods_id == selectedColor },
              { 'image-item__fill-layout': fillLayout }
            ]}
            data-sku={item.goods_sn}
            data-goodsid={item.goods_id}
            data-soldout={Number(item.isSoldoutColor)}
            data-event-category={eventCategory}
            data-review-location={reviewLocation}
            data-location={from}
            data-source={colorConf && colorConf.analysisConfig && colorConf.analysisConfig.source}
            data-promotion-type={promotionTagInfos && promotionTagInfos[item.goods_id] && promotionTagInfos[item.goods_id].typeId || '-'}
            data-image-tp="large"
            data-is-attr-fold={analysisInfo.isAttrFold}
            da-event-click="1-6-1-32"
            role="largeImage"
            vOn:click={() => { switchColor(item) }}
            {...{ directives: [{ name: 'ada', value: { level: 1, pos: index } }] }}
          >
          
            {/* <img
              data-src={item['goods_img']}
              src={lazyImg}
              data-design-width="0"
              class="lazyload large-image"
            /> */}

            <CropImageContainerJsx
              fixedRatio={fixedRatio}
              isSupportCropImage={isSupportCropImage}
              imgSrc={item['goods_img']}
              imgProps={{
                src: lazyImg,
                dataDesignWidth: 0,
                class: 'lazyload'
              }}
            />

            <span>{ item.goods_title }</span>

            {renderSoldOut(item)}

            <ClientOnly>
              <ColorV2AddTag
                item={item}
                isLargeImage={true}
                promotionTagInfos={promotionTagInfos}
                labelExpose={labelExpose}
                publicCdn={publicCdn}
              />
            </ClientOnly>
          </li>
        )
      })
    }

    // 售罄蒙版
    const renderSoldOut = (item) => {
      if (item.isSoldoutColor) {
        return (
          <div class="sold-out-frame"></div>
        )
      }
    }

    return (
      <div class="goods-color__large-image">
        {renderListBox()}
      </div>
    )
  }
}

</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.goods-color__large-image {
  width: 100%;
  overflow-x: scroll;
  margin: .106667rem 0 .213333rem 0;

  &::-webkit-scrollbar {
    display: none;
  }

  .image-list {
    display: flex;
    padding-top: .213333rem;

    .image-item {
      position: relative;
      margin-left: .213333rem;
      padding: .106667rem;
      box-sizing: border-box;
      overflow: hidden;
      width: 3.2rem;
      // height: 4.8rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      overflow: visible;
      .border-dpr(border, 2px, transparent);

      &:first-child {
        margin-left: 0;
      }

      span {
        height: 18px;
        font-size: .32rem;
        color: #767676;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        text-align: center;
      }

      &.active {
        .border-dpr(border, 2px, #222);

        span {
          color: #222;
        }
      }

      .sold-out-frame {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.6);
      }

      .evolu, .hot {
        position: absolute;
        .right(-.106667rem);
        top: -.186667rem;
        width: .533333rem;
        height: .4rem;
        border-radius: 2px;
      }
    }

    .image-item__fill-layout {
      width: 3.04rem;
      margin-left: .12rem;
    }
  }

  .tag-discount {
      position: absolute;
      top: 0;
      left: 100%;
      transform-origin: 0 0;
      transform: scale(.5) translate(-70%, -50%);

      border: 0.042rem solid rgba(255, 255, 255, 0.86);
      border-radius: 0.1rem;
      padding: 0.1rem;
      overflow: hidden;

      font-size: 0.42rem;
      line-height: 1.25;
      white-space: nowrap;
      background: @sui_color_promo_bg;
      color: @sui_color_promo_dark;
  }
  .tag-promotion {
      position: absolute;
      top: 0;
      left: 100%;
      transform-origin: 0 0;
      transform: translate(-70%, -50%);
      border: 0.021rem solid rgba(255, 255, 255, 0.86);
      border-radius: 0.053rem;
      padding: 0.026rem 0.026rem 0.026rem 0.053rem;
      overflow: hidden;
      font-size: 0.32rem;
      line-height: 1;
      background: @sui_color_promo_bg;
      color: @sui_color_promo_dark;
  }
}
</style>
